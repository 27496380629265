import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";
import ScrollSection from "../components/scroll-section/scroll-section";

class JournalIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          type="page"
          content={{
            title: "Travel Destinations",
            seoDescription: "Travel Destinations",
          }}
        />
        <ScrollSection>
          <Hero title={"Coming soon".toUpperCase()} border={false} />
        </ScrollSection>
      </Layout>
    );
  }
}

export default JournalIndex;
